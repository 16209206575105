<template>
  <NavbarAuth />
  <br><br><br>
  <div className="container pt-4 pb-5">
		<div className="row">
			<div className="col-md-8">
				<h3>Sertifikat Genbox</h3>
				<p><i class="text-muted">Belum Dapat Sertifikat</i></p>
			</div>
			<div className="col-md-4">
				<div className="card shadow mb-3">
				<div className="card-body">
					<h1 className="card-title">kodingLife</h1>
					<h6 className="card-subtitle mb-2 text-muted">Komunitas IT Indonesia</h6>
					<p className="card-text">
						Tambah relasi dan pengetahuan mu dengan join komunitas kami
					</p>
					<a href="#" className="btn btn-success">Join Komunitas</a>
				</div>
				</div>
				<iframe
					src="https://www.instagram.com/p/ClxznBqpskc/embed/"
					title="Instagram Post"
					width="100%" 
					height="100%"
					scrolling="no"
					frameborder="0"
					allowtransparency="true" 
          class="shadow-lg"
				></iframe>
			</div>
		</div>
		</div>
</template>

<script>
import NavbarAuth from '@/components/NavbarAuth.vue'
import Cookies from 'js-cookie';

export default {
  name: "DashboardView",
  components: {
    NavbarAuth
  },
  mounted(){
    const dataAuth = Cookies.get('auth');
    
    if(!dataAuth){
      this.$router.push('/login')
      return false
    }
  }
}
</script>

<style>
</style>