<template>
  <NavbarRoot />
  <div class="daftar d-flex justify-content-center align-items-center">
    <div class="daftar-container card bg-white shadow-lg">
      <div class="row ">
        <div class="col-md">
          <div class="bg-panel rounded">

          </div>
        </div>
        <div class="col-md">
          <div class="container py-3 form overflow-auto">
            <h3>Daftar</h3>
            <div class="">
              <p>Sudah punya akun? <router-link to="/login">Login</router-link></p>
            </div>
            <br>
            <form @submit.prevent="reg">
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">Username </label>
                <input type="text" class="form-control" id="exampleInputEmail1" v-model="username" required>
              </div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">Nama </label>
                <input type="text" class="form-control" id="exampleInputEmail1" v-model="nama" required>
              </div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">Email</label>
                <input type="email" class="form-control" id="exampleInputEmail1" v-model="email" aria-describedby="emailHelp" required>
              </div>
              <div class="mb-3">
                <label for="wa" class="form-label">Whatsapp</label>
                <input type="text" class="form-control" id="wa" v-model="wa" required>
              </div>
              <div class="mb-3">
                <label class="form-label">Jenis Kelamin</label><br>
                <input type="radio" id="jka" value="p" class="me-2" name="jk" v-model="jenis_kelamin" required>
                <label for="jka" class="me-4">Perempuan</label>
                <input type="radio" id="jkb" value="l" class="me-2" name="jk" v-model="jenis_kelamin" required>
                <label for="jkb">Laki - Laki</label> 
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">Password</label>
                <input type="password" class="form-control" id="exampleInputPassword1" v-model="password" required>
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">Konfirmasi</label>
                <input type="password" class="form-control" id="exampleInputPassword1" v-model="repassword" required>
              </div>
              <button type="submit" class="btn btn-neon">Daftar</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarRoot from '@/components/NavbarRoot.vue'
import axios from 'axios'
import Swal from 'sweetalert2'
import Cookies from 'js-cookie'

const apiUrl = process.env.VUE_APP_API_URL
export default {
  name: "DaftarView",
  data(){
    return {
      username: '',
      nama: '',
      email: '',
      wa: '',
      jenis_kelamin: '',
      password: '',
      repassword: '',
    }
  },
  mounted() {
    const dataAuth = Cookies.get('auth');

    if(dataAuth){
      let auth = JSON.parse(dataAuth)
      let status = auth.status
      if(status === 0){
        this.$router.push('/pay')
      }else if(status === 1){
        this.$router.push('/dashboard')
      }
    }
    document.querySelector('.navbar').classList.add('navbar-light')
    document.querySelector('.navbar').classList.remove('navbar-dark')
  },
  components: {
    NavbarRoot
  },
  methods: {
    reg(){
      document.querySelector('.load').classList.add('load-active');
      if(this.password == this.repassword){
        axios.post(apiUrl + "register", {
          name: this.username,
          email: this.email,
          password: this.password,
        })
        .then(res => {
          axios.post(apiUrl + "login", {
            email: this.email,
            password: this.password,
          })
            .then(login => {
                let status = login.data.data.status
                const headers = {
                    Authorization: `Bearer ${login.data.data.token}`
                };
                
                axios.post(apiUrl + "biodata/add", {
                    nama: this.nama,
                    user_id: res.data.user.id,
                    jenis_kelamin: this.jenis_kelamin,
                    whatsapp: this.wa
                  }, { headers })

                Cookies.set('auth', JSON.stringify(login.data.data));
                document.querySelector('.load').classList.remove('load-active');
                Swal.fire({
                  title: "Berhasil Mendaftar!",
                  text: "Selamat anda telah terdaftar!",
                  icon: "success"
                });

                if(status === 0){
                  this.$router.push('/pay')
                }
              })
        })
        .catch(() => {
              document.querySelector('.load').classList.remove('load-active');
              Swal.fire({
                title: "Gagal Mendaftar!",
                text: "Perhatikan data-data anda!",
                icon: "error"
              });
            })
          
      }else {
        document.querySelector('.load').classList.remove('load-active');
        Swal.fire({
          title: "Gagal Mendaftar!",
          text: "Password dan konfirmasi password salah!",
          icon: "error"
        });
      }
    }
  }
}
</script>

<style>
  .daftar {
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  .bg-panel {
    width: 100%;
    background-size: cover;
    background-image: url(@/assets/img/docs/high.jpg);
    background-position: center;
    height: 55.5vh;
  }
  
  .daftar-container {
    border: none;
    width: 55%;
    height: 60vh;
    padding: 20px;
  }
  
  .form {
    height: 55.5vh;
  }
</style>