<template>
  <nav class="navbar navbar-expand-lg navbar-dark fixed-top p-3">
    <div class="container">
      <router-link class="navbar-brand" to="/">
        <img src="@/assets/img/logo.svg" class="me-2" />
        Genbox Academy
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link active" aria-current="page" to="/">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/about">About</router-link>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" href="#">Research & Development</a>
          </li> -->
          <li class="nav-item me-4">
            <a class="nav-link" href="https://medium.com/@risanussy07">Articles</a>
          </li>
          <li class="nav-item">
            <router-link class="btn btn-neon" to="/login">Masuk</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavbarHome',
  mounted() {
    this.scrollHandler = function() {
      // Mendapatkan tinggi scroll dari bagian atas halaman
      let scrollHeight = window.scrollY || document.documentElement.scrollTop;

      let windowHeight = window.innerHeight;
      let relativeHeight = windowHeight - 80;

      // Cek jika tinggi scroll lebih dari 1000px
      if (scrollHeight > relativeHeight) {
          document.querySelector('.navbar').classList.add('navbar-light')
          document.querySelector('.navbar').classList.remove('navbar-dark')
          // document.querySelector('nav').style.backgroundColor = 'rgba(0, 0, 0, 0.3)'
          document.querySelector('nav').style.backgroundColor = 'white'
          document.querySelector('.navbar-brand').style.color = 'black'
          document.querySelectorAll('.nav-link')[0].style.color = ' rgba(0, 0, 0, 1)'
          document.querySelectorAll('.nav-link')[1].style.color = ' rgba(0, 0, 0, 0.55)'
          document.querySelectorAll('.nav-link')[2].style.color = ' rgba(0, 0, 0, 0.55)'
          document.querySelector('nav').style.boxShadow = '0 2px 6px rgba(0, 0, 0, 0.1)'
          document.querySelector('nav').style.transition = '.5s'
        }else {
          document.querySelector('nav').style.boxShadow = 'none'
          document.querySelector('.navbar').classList.remove('navbar-light')
          document.querySelector('.navbar').classList.add('navbar-dark')
          document.querySelector('.navbar-brand').style.color = 'white'
          document.querySelector('nav').style.backgroundColor = 'rgba(255, 255, 255, 0.001)'
          document.querySelectorAll('.nav-link')[0].style.color = ' rgba(255, 255, 255, 1)'
          document.querySelectorAll('.nav-link')[1].style.color = ' rgba(255, 255, 255, 0.55)'
          document.querySelectorAll('.nav-link')[2].style.color = ' rgba(255, 255, 255, 0.55)'
          document.querySelector('nav').style.transition = '.5s'
      }
    };

    window.addEventListener('scroll', this.scrollHandler);
  },
  beforeUnmount() {
    // Menghapus event listener sebelum komponen dihancurkan
    window.removeEventListener('scroll', this.scrollHandler);
  }
}
</script>

<style scoped>
  img {
    height: 32px
  }

  nav {    
    background: rgba(255, 255, 255, 0.001);
    backdrop-filter: blur(12px);
  }

  .btn-neon {
    background-color: #32E0C4;
    border: 1px solid #32E0C4;
    color: #004643;
    box-shadow: 0px 0px 18px rgba(50, 224, 196, 0.45);
  }
</style>