<template>
  <NavbarRoot />
  <div class="login d-flex justify-content-center align-items-center">
    <div class="login-container card bg-white shadow-lg">
      <div class="row ">
        <div class="col-md">
          <div class="bg-lpanel rounded">

          </div>
        </div>
        <div class="col-md">
          <div class="container py-3  ">
            <h3>Login</h3>
            <small>Masuk sebagai user</small>
            <br>
            <br>
            <form @submit.prevent="login">
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">Email address</label>
                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" v-model="email">
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">Password</label>
                <input type="password" class="form-control" id="exampleInputPassword1" v-model="password">
              </div>
              <button type="submit" class="btn btn-neon">Masuk</button>
              <div class="mt-3">
                <p>Belum punya akun? <router-link to="/daftar">Daftar</router-link></p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarRoot from '@/components/NavbarRoot.vue'
import axios from 'axios'
import Swal from 'sweetalert2'
import Cookies from 'js-cookie'

const apiUrl = process.env.VUE_APP_API_URL
export default {
  name: "LoginView",
  components: {
    NavbarRoot
  },
  mounted() {
    const dataAuth = Cookies.get('auth');

    if(dataAuth){
      let auth = JSON.parse(dataAuth)
      let status = auth.status
      if(status === 0){
        this.$router.push('/pay')
      }else if(status === 1){
        this.$router.push('/dashboard')
      }
    }
    document.querySelector('.navbar').classList.add('navbar-light')
    document.querySelector('.navbar').classList.remove('navbar-dark')
  },
  data(){
    return {
      email: '',
      password: ''
    }
  },
  methods: {
    login(){
      document.querySelector('.load').classList.add('load-active');
      axios.post(apiUrl + "login", {
            email: this.email,
            password: this.password,
          })
            .then(res => {
              let status = res.data.data.status
              Cookies.set('auth', JSON.stringify(res.data.data));
              document.querySelector('.load').classList.remove('load-active');
              Swal.fire({
                title: "Success!",
                text: "Berhasil Login!",
                icon: "success"
              });
              if(status === 0){
                this.$router.push('/pay')
              }else if(status === 1){
                this.$router.push('/dashboard')
              }
            })
            .catch(() => {
              document.querySelector('.load').classList.remove('load-active');
              Swal.fire({
                title: "Gagal Login!",
                text: "Email atau Password Salah!",
                icon: "error"
              });
            })
    }
  }
}
</script>

<style>
  .bg-lpanel {
    width: 100%;
    background-size: cover;
    background-image: url(@/assets/img/panel.jpg);
    background-position: center;
    height: 55.5vh;
  }

  .login {
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

  .login-container {
    border: none;
    width: 55%;
    height: 60vh;
    padding: 20px;
  }
</style>