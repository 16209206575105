<template>
  <NavbarAuth />
  <br><br><br>
  <div class="container pt-5">
    <div class="row">
      <div class="col-md">
        <div class="d-flex gap-3 flex-wrap">
          <div id="bootcamp" @click="chooseA" class="card price" style="width: 300px;">
            <div class="card-body">
              <div class="text-center">
                <h4 class="fw-light">Kelas Bootcamp</h4>
                <p>Belajar Bersama dengan peserta lainnya juga</p>
                <h2>567.000 IDR</h2>
                <small>/ Per Bulan (16 jam pertemuan)</small>
              </div>
              <br>
              <h5>Services</h5>
              <table cellpadding="3">
                <tr>
                  <td class="pe-2"><i class="fa-solid fa-check text-success"></i></td>
                  <td>Online</td>
                </tr>
                <tr>
                  <td class="pe-2"><i class="fa-solid fa-xmark text-danger"></i></td>
                  <td>Offline</td>
                </tr>
                <tr>
                  <td class="pe-2"><i class="fa-solid fa-check text-success"></i></td>
                  <td>Backup Materi</td>
                </tr>
                <tr>
                  <td class="pe-2"><i class="fa-solid fa-check text-success"></i></td>
                  <td>VIdeo Materi</td>
                </tr>
                <tr>
                  <td class="pe-2"><i class="fa-solid fa-xmark text-danger"></i></td>
                  <td>Flexible waktu</td>
                </tr>
                <tr>
                  <td class="pe-2"><i class="fa-solid fa-check text-success"></i></td>
                  <td>Job Connector</td>
                </tr>
              </table>
            </div>
          </div>
          <div id="private" @click="chooseB" class="card price" style="width: 300px;">
            <div class="card-body">
              <div class="text-center">
                <h4 class="fw-light">Kelas Privat</h4>
                <p>Belajar Berdua hanya dengan mentor face to face</p>
                <h2>3.433.000 IDR</h2>
                <small>/ Per Bulan (16 jam pertemuan)</small>
              </div>
              <br>
              <h5>Services</h5>
              <table cellpadding="3">
                <tr>
                  <td class="pe-2"><i class="fa-solid fa-check text-success"></i></td>
                  <td>Online</td>
                </tr>
                <tr>
                  <td class="pe-2"><i class="fa-solid fa-check text-success"></i></td>
                  <td>Offline</td>
                </tr>
                <tr>
                  <td class="pe-2"><i class="fa-solid fa-check text-success"></i></td>
                  <td>Backup Materi</td>
                </tr>
                <tr>
                  <td class="pe-2"><i class="fa-solid fa-check text-success"></i></td>
                  <td>VIdeo Materi</td>
                </tr>
                <tr>
                  <td class="pe-2"><i class="fa-solid fa-check text-success"></i></td>
                  <td>Flexible waktu</td>
                </tr>
                <tr>
                  <td class="pe-2"><i class="fa-solid fa-check text-success"></i></td>
                  <td>Job Connector</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md">
        <div class="card shadow">
          <div class="card-body">
            <h3>Payment</h3>
            <br>
            <h6>{{ first_name }} | {{ last_name }}</h6>
            <h6>{{ email }}</h6>
            <hr>
            <table cellpadding="5">
              <tr>
                <td>Program</td>
                <td>:</td>
                <td>{{ kelas }}</td>
              </tr>
              <tr>
                <td>Total</td>
                <td>:</td>
                <td><b>{{ formatRupiah(total) }}</b></td>
              </tr>
            </table>
            <br>
            <form @submit.prevent="pay" class="g-3 needs-validation">
              <div class="form-check mb-3">
                <input class="form-check-input" type="checkbox" value="" id="invalidCheck" required>
                <label class="form-check-label" for="invalidCheck">
                  <a href="https://drive.google.com/file/d/1DhFuU9dfFm9OPr0_psyg4UlHWxGbaKrs/view?usp=sharing" target="_blank">( Baca E-Kontrak )</a> <b class="text-danger">baca kontrak dahulu dan ceklis untuk setuju</b> dengan persyaratan yang ada di kontrak untuk bisa melanjutkan pembayaran
                </label>
                <div class="invalid-feedback">
                  Baca Terlebih dahulu kontrak sebelum bayar!
                </div>
              </div>
              <button class="btn btn-lg btn-secondary" id="paybtn">Bayar Sekarang</button>
            </form>
          </div>
        </div>
        <div class="card shadow mt-3">
          <div id="snap-container"></div>
        </div>
      </div>
    </div>
  </div>
  <br><br><br>
</template>

<script>
import NavbarAuth from '@/components/NavbarAuth.vue'
import axios from 'axios'
import Swal from 'sweetalert2'
import Cookies from 'js-cookie';

const apiUrl = process.env.VUE_APP_API_URL
export default {
  name: "DaftarView",
  data(){
    return {
      first_name: '', 
      last_name: '', 
      wa: '', 
      email: 'risa@risa.com',
      total: 0,
      kelas: 'Pilih kelas',
    }
  },
  mounted(){
    const dataAuth = Cookies.get('auth');
    
    if(!dataAuth){
      this.$router.push('/login')
      return false
    }
    
    let auth = JSON.parse(dataAuth)
    
    // Menyertakan token ke dalam header Authorization
    const headers = {
        Authorization: `Bearer ${auth.token}`
    };
    this.email = auth.email
    this.last_name = auth.name
    axios.get(apiUrl+'biodata/show/' + auth.id, {headers})
      .then(res => {
        this.first_name = res.data.nama
        this.wa = res.data.whatsapp
      })
  },
  components: {
    NavbarAuth
  },
  methods: {
    formatRupiah(value) {
      // Gunakan regex untuk memformat nilai menjadi format rupiah
      const formattedValue = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return `Rp ${formattedValue}`;
    },
    chooseA(){
      document.querySelector('#paybtn').classList.add('btn-primary')
      document.querySelector('#paybtn').classList.remove('btn-secondary')
      document.querySelector('#bootcamp').classList.add('cactive')
      document.querySelector('#private').classList.remove('cactive')

      this.kelas = 'Bootcamp'
      this.total = 567000
    },
    chooseB(){
      document.querySelector('#paybtn').classList.add('btn-primary')
      document.querySelector('#paybtn').classList.remove('btn-secondary')
      document.querySelector('#bootcamp').classList.remove('cactive')
      document.querySelector('#private').classList.add('cactive')
      
      this.kelas = 'Privat'
      this.total = 3433000
    },
    pay(){
      if(this.total === 0 && this.kelas === 'Pilih kelas') {
        Swal.fire({
          title: "Belum Memilih Kelas!",
          text: "Klik kelas yang ingin anda ikuti!",
          icon: "error"
        });

        return false
      }

      axios.post(apiUrl+'program/midtrans', {
        first_name: this.first_name, last_name: this.last_name, wa: this.wa, email: this.email, total: this.total
      })
        .then(res => {
          window.snap.embed(res.data.token, {
            embedId: 'snap-container',
            onSuccess: function(){
              document.querySelector('.load').classList.add('load-active');
              const dataAuth = Cookies.get('auth');
              let auth = JSON.parse(dataAuth)

              // Menyertakan token ke dalam header Authorization
              const headers = {
                  Authorization: `Bearer ${auth.token}`
              };
              
              axios.post(apiUrl + 'update-status', { status: 1 }, { headers })
                .then(() => {
                    axios.post(apiUrl + "program/add", {
                        user_id: auth.id,
                        nama: this.kelas,
                        method: "online",
                        status: 1
                      })

                    document.querySelector('.load').classList.remove('load-active');
                    Swal.fire({
                      title: "Success!",
                      text: "Pembayaran berhasil!",
                      icon: "success"
                    });
                    // Gunakan arrow function untuk memastikan konteks yang benar
                    window.location.replace('/dashboard');
                })
                .catch(error => {
                    console.error('Error updating status:', error);
                });
            },
            onPending: function(){
              Swal.fire({
                title: "Pending!",
                text: "Selesaikan dulu pembayaran!",
                icon: "warning"
              });
            },
            onError: function(){
              Swal.fire({
                title: "Error!",
                text: "Terjadi kesalahan dalam pembayaran!",
                icon: "error"
              });
            },
            onClose: function(){
              Swal.fire({
                title: "Keluar!",
                text: "Anda keluar tanpa menyelesaikan pembayaran!",
                icon: "error"
              });
            }
          });
        })
    }
  }
}
</script>

<style>
  #snap-container {
    width: 100%;
  }

  .cactive {
    border: 2px solid seagreen;
    box-shadow: 0px 0px 24px rgba(50, 224, 196, 0.35);
  }

  .price {
    cursor: pointer;
    transition: .5s;
  }

  .price:hover {
    transform: translateY(-10px);
  }
</style>