<template>
  <router-view />
  <LoadRoot/>
</template>

<script>
import LoadRoot from '@/components/LoadRoot.vue'

export default {
  name: "App",
  components: {
    LoadRoot
  }
}
</script>
