<template>
  <nav class="navbar navbar-expand-lg text-dark fixed-top p-3">
    <div class="container">
      <span class="navbar-brand">
        <img src="@/assets/img/logo.svg" class="me-2" />
        Genbox Academy
      </span>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item me-4">
            <a class="nav-link" href="https://medium.com/@risanussy07">Articles</a>
          </li>
          <li class="nav-item me-4">
            <span class="nav-link fw-bold">{{ username }}</span>
          </li>
          <li class="nav-item me-2">
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#profil">
              <i class="fa-solid fa-address-card me-2"></i> Data Diri
            </button>
          </li>
          <li class="nav-item">
            <button class="btn btn-outline-danger" @click="logout">Keluar</button>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <!-- Modal -->
  <div class="modal fade" id="profil" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Data Diri</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="updateBiodata">
            <div class="row">
              <div class="col-md">
                <div class="mb-4 rounded-circle bg-secondary m-auto d-flex justify-content-center align-items-center overflow-hidden" style="width: 180px;height: 180px;">
                  <div v-if="biodata.foto" style="width: 180px;height: 180px;">
                    <img :src="'https://genbox.tech/public/images/'+biodata.foto" :alt="biodata.foto" style="height:105%;">
                  </div>
                  <div v-else>
                    <i class="fa-solid fa-user text-white" style="font-size: 92px;"></i>
                  </div>
                </div>
                <label class="mb-3">{{ email }}</label>
                <div class="mb-3">
                  <input type="file" class="form-control" placeholder="" @change="handleFileUpload" accept="image/*">
                </div>
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" v-model="status_kerja">
                  <label class="form-check-label" for="flexSwitchCheckDefault">Sudah Bekerja</label>
                </div>
                <div class="mb-3">
                  <label class="form-label">Nama</label>
                  <input type="text" class="form-control" placeholder="" v-model="biodata.nama">
                </div>
              </div>
              <div class="col-md">
                <div class="mb-3">
                  <label class="form-label">Alamat</label>
                  <input type="text" class="form-control" placeholder="" v-model="biodata.alamat">
                </div>
                <div class="mb-3">
                  <label class="form-label">Whatsapp</label>
                  <input type="text" class="form-control" placeholder="" v-model="biodata.whatsapp">
                </div>
                <div class="mb-3">
                  <label class="form-label">Tempat Lahir</label>
                  <input type="text" class="form-control" placeholder="" v-model="biodata.tempat_lahir">
                </div>
                <div class="mb-3">
                  <label class="form-label">Tanggal Lahir</label>
                  <input type="date" class="form-control" placeholder="" v-model="biodata.Tanggal_lahir">
                </div>
                <label class="form-label">Link :</label><br>
                <div class="mb-3">
                  <label class="form-label">Portfolio</label>
                  <input type="text" class="form-control" placeholder="" v-model="biodata.portfolio">
                </div>
                <div class="mb-3">
                  <label class="form-label">Github</label>
                  <input type="text" class="form-control" placeholder="" v-model="biodata.github"> 
                </div>
              </div>
              <div class="col-md">
                <div class="mb-3">
                  <label class="form-label">Pendidikan</label>
                  <input type="text" class="form-control" placeholder="" v-model="biodata.pendidikan">
                </div>
                <div class="mb-3">
                  <label class="form-label">Pengalaman</label>
                  <textarea class="form-control" placeholder="" v-model="biodata.pengalaman"></textarea>
                </div>
                <label class="form-label">Teknologi yang digunakan :</label><br>
                <div class="mb-3">
                  <label class="form-label">Frontend</label>
                  <input type="text" class="form-control" placeholder="" v-model="biodata.fe">
                </div>
                <div class="mb-3">
                  <label class="form-label">Backend</label>
                  <input type="text" class="form-control" placeholder="" v-model="biodata.be">
                </div>
                <div class="mb-3">
                  <label class="form-label">Database</label>
                  <input type="text" class="form-control" placeholder="" v-model="biodata.db">
                </div>
              </div>
            </div>
            <button class="btn btn-lg btn-primary" type="submit">Update & Save</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import Cookies from 'js-cookie';

const apiUrl = process.env.VUE_APP_API_URL
export default {
  name: 'NavbarHome',
  data(){
    return {
      username: "",
      email: "",
      biodata: {},
      selectedFile: null,
      status_kerja: false,
    }
  },
  mounted() {
    document.querySelector('.navbar').classList.add('navbar-light')
    document.querySelector('.navbar').classList.remove('navbar-dark')
    const dataAuth = Cookies.get('auth');
    let auth = JSON.parse(dataAuth)

    const headers = {
        Authorization: `Bearer ${auth.token}`
    };

    this.username = auth.name
    this.email = auth.email

    axios.get(apiUrl + "biodata/show/" + auth.id, { headers })
      .then((result) => {
        this.biodata = result.data;
        this.status_kerja = this.biodata.status_kerja === '1';
      })
      .catch(() => {
        console.log("bad request");
      });

  },
  methods: {
    handleFileUpload(event) {
      this.selectedFile = event.target.files[0];
    },
    logout() {
        document.querySelector('.load').classList.add('load-active');
        const dataAuth = Cookies.get('auth');
        let auth = JSON.parse(dataAuth)

        // Menyertakan token ke dalam header Authorization
        const headers = {
          Authorization: `Bearer ${auth.token}`,
        };

        axios.post(apiUrl + "logout",{}, { headers })
            .then(() => {
              document.querySelector('.load').classList.remove('load-active');
              Cookies.remove('auth');
              this.$router.push('/login')
            })
            .catch(() => {
              document.querySelector('.load').classList.remove('load-active');
            });
    },
    updateBiodata() {
      const dataAuth = Cookies.get('auth');
      let auth = JSON.parse(dataAuth)

      const headers = {
        Authorization: `Bearer ${auth.token}`,
        'Content-Type': 'multipart/form-data',
      };
      
      const formData = new FormData();
      if (!this.biodata.foto || this.biodata.foto === null) {
        formData.append('foto', this.selectedFile);
      }
      formData.append('status_kerja', this.status_kerja ? '1' : '0'); // Ubah ke string sesuai kebutuhan

      Object.keys(this.biodata).forEach(key => {
        if (key !== 'status_kerja' && key !== 'foto') {
          formData.append(key, this.biodata[key]);
        }
      });

      axios.post(apiUrl + "biodata/edit/" + this.biodata.id, formData, { headers })
        .then(() => {
          Swal.fire({
            title: "Success!",
            text: "Update berhasil!",
            icon: "success"
          });
        })
        .catch(() => {
          console.log("Gagal memperbarui biodata");
          // Tambahkan logika atau pesan kesalahan
        });
    }
  }
}
</script>

<style scoped>
  img {
    height: 32px
  }

  nav {    
    background: rgba(255, 255, 255, 0.001);
    backdrop-filter: blur(12px);
  }

  .btn-neon {
    background-color: #32E0C4;
    border: 1px solid #32E0C4;
    color: #004643;
    box-shadow: 0px 0px 18px rgba(50, 224, 196, 0.45);
  }
</style>