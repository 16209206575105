<template>
  <div class="load text-white">
    <div class="spinner-border" role="status" style="width: 60px;height: 60px;">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>


<script>
export default {
  name: 'LoadRoot',
}
</script>

<style>
.load {
  position: fixed;
  top: 0;left: 0;right: 0;bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 9999;
  display: none;
  justify-content: center;
  align-items: center;
}

.load-active {
  display: flex !important;
}
</style>