<template>
  <footer class="bg-genbox">
    <div class="container py-5">
      <div class="row">
        <div class="col-sm-2 mb-4">                   
          <h3 class="fs-5">Partner</h3>
          <ul class="mt-3 list-group" style="list-style: none;">
            <li class="mb-1"><span class="text-decoration-none text-dark  fs-5 fs-sm">Pollux Integral</span></li>
            <li class="mb-1"><span class="text-decoration-none text-dark  fs-5 fs-sm">CRUD Studio</span></li>
            <li class="mb-1"><span class="text-decoration-none text-dark  fs-5 fs-sm">Kanneza Technology</span></li>
            <li class="mb-1"><span class="text-decoration-none text-dark  fs-5 fs-sm">Stara Enterprise</span></li>
            <li class="mb-1"><span class="text-decoration-none text-dark  fs-5 fs-sm">Astrajingga</span></li>
          </ul>
        </div>
        <div class="col-sm-2 mb-4">                   
          <h3 class="fs-5">Other Service</h3>
          <ul class="mt-3 list-group" style="list-style: none;">
            <li class="mb-1"><span class="text-decoration-none text-dark  fs-5 fs-sm">Human Resource</span></li>
            <li class="mb-1"><span class="text-decoration-none text-dark  fs-5 fs-sm">Software Development</span></li>
            <li class="mb-1"><span class="text-decoration-none text-dark  fs-5 fs-sm">IoT Development</span></li>
            <li class="mb-1"><span class="text-decoration-none text-dark  fs-5 fs-sm">IT Consultant</span></li>
          </ul>
        </div>
        <div class="col-sm-2 mb-4 pe-5">                    
          <h3 class="fs-5">Contact us</h3>
          <ul class="mt-3 list-group" style="list-style: none;">
            <li class="mb-1"><a href="mailto:hello@genbox.id" class="text-decoration-none text-dark  fs-5 fs-sm">hello@genbox.id</a></li>
            <li><a href="https://wa.me/6289509589977" class="text-decoration-none text-dark  fs-5 fs-sm">62 895 0958 9977</a></li>
          </ul>
          <br>
          <h3 class="fs-5">Sosmed</h3>
          <ul class="mt-3 list-group" style="list-style: none;">
            <li class="mb-1 d-flex align-items-center">
              <a href="https://www.instagram.com/genbox.id/" class="text-decoration-none text-dark  fs-3"><i class="fa-brands fa-instagram"></i></a>
              <a href="https://web.facebook.com/profile.php?id=61553450249679" class="text-decoration-none text-dark  fs-4 ms-3"><i class="fa-brands fa-facebook"></i></a>
              <a href="#" class="text-decoration-none text-dark  fs-4 ms-3"><i class="fa-brands fa-discord"></i></a>
              <a href="#" class="text-decoration-none text-dark  fs-4 ms-3"><i class="fa-brands fa-x-twitter"></i></a>
            </li>
          </ul>
        </div>
        <div class="col-sm-6 mb-4">
          <div class="row">
            <div class="col-sm">
              <h3 class="fs-5">Genbox Universe</h3>
              <h6 class="mb-3">PT Generasi Digital Nasional</h6>
              <p>Jl. Kebagusan II No.32, RT.9/RW.6, Kebagusan, Ps. Minggu, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12520</p>
              <small class="mt-4 d-flex">&copy; Copyright 2024 Genbox</small>
            </div>
            <div class="col-sm">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.674479055595!2d106.82666761468626!3d-6.306428895435859!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69edb7a3004d11%3A0x3af7d406f0678521!2sGenbox%20Academy!5e0!3m2!1sid!2sid!4v1678335105427!5m2!1sid!2sid" width="100%" height="200px" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style="border: 0px;"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>


<script>
export default {
  name: 'FooterRoot',
}
</script>

<style>
  @media (max-width: 567px) {
    .fs-sm {
      font-size: 16px !important;
    }
  }
</style>