<template>
  <Navbar />
  <div class="home text-white">
    <header>
      <div class="container">
        <div class="row">
          <div class="col-sm">
            <p class="fw-light sub-title">Belajar Fullstack untuk Full Senyum</p>
            <h1 class="title"><span class="char">Gen</span>eration Out<br/>of The <span class="char">Box</span>.</h1>
            <br>
            <div class="mt-5">
              <router-link to="/daftar" class="btn btn-lg btn-main">
                Daftar Sekarang
                <i class="fa-solid fa-arrow-right ms-1"></i>
              </router-link>
              <a href="https://wa.me/6289509589977" class="btn btn-lg text-white">Whatsapp Kami</a>
            </div>
            <p class="fs-6 mt-4 text-muted">Sudah ada akun? <router-link class="text-muted" to="/login">Login</router-link></p>
          </div>
        </div>
      </div>
    </header>
    <img src="@/assets/img/cover.png" alt="cover" class="cover-heading">
  </div>
  <div class="container py-5 resp-py">
    <div class="card border-0 shadow p-5 resp-p">
      <div class="card-body">
        <div class="container">
          <div class="row">
            <div class="col-sm">
              <div class="h-100 d-flex justify-content-center flex-column">
                <h2>
                  Branding Dirimu<br>Bersama <span class="text-neon">Genbox Academy</span>
                </h2>
                <p class="m-0">Belajar mulai dari nol, tanpa harus memiliki background IT</p>
              </div>
            </div>
            <div class="col-sm">
              <div class="d-flex justify-content-evenly">
                <div class="d-flex align-items-center flex-column">
                  <div class="mb-2 rounded-circle border border-2 d-flex justify-content-center align-items-center resp-num" style="width: 110px; height: 110px;">
                    <div>
                      <h3>20</h3>
                    </div>
                  </div>
                  <small>Jam Berlangsung</small>
                </div>
                <div class="d-flex align-items-center flex-column">
                  <div class="mb-2 rounded-circle border border-2 d-flex justify-content-center align-items-center resp-num" style="width: 110px; height: 110px;">
                    <div>
                      <h3>50</h3>
                    </div>
                  </div>
                  <small>Materi</small>
                </div>
                <div class="d-flex align-items-center flex-column">
                  <div class="mb-2 rounded-circle border border-2 d-flex justify-content-center align-items-center resp-num" style="width: 110px; height: 110px;">
                    <div>
                      <h3>20</h3>
                    </div>
                  </div>
                  <small>Member</small>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="my-5 py-5">
      <h4 class="text-center mb-5">Kenapa Pilih Genbox?</h4>
      <div class="row">
        <div class="col-sm">
          <div class="p-3 mb-3">
            <div >
              <div class="row">
                <div class="col-2">
                  <div class="rounded bg-neon d-flex justify-content-center align-items-center resp-icon" style="width: 55px; height: 55px;">
                    <i class="fa-solid fa-gear fs-2 resp-fs text-white"></i>
                  </div>
                </div>
                <div class="col-10">
                  <div>
                    <h5>Memiliki Beragam Program</h5>
                    <small class="d-inline-block pe-4">Genbox menyediakan banyak program untuk semua kalangan sehingga talent dapat memilih program sesuai kebutuhannya.</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-3">
            <div >
              <div class="row">
                <div class="col-2">
                  <div class="rounded bg-neon d-flex justify-content-center align-items-center resp-icon" style="width: 55px; height: 55px;">
                    <i class="fa-solid fa-briefcase fs-2 resp-fs text-white"></i>
                  </div>
                </div>
                <div class="col-10">
                  <div>
                    <h5>Langsung Disalurkan Kerja</h5>
                    <small class="d-inline-block pe-4">Genbox juga berkerja sama dengan beberapa perusahaan yang ada di Indonesia dan siap menyalurkan lulusan training kami.</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm">
          <div class="p-3 mb-3">
            <div >
              <div class="row">
                <div class="col-2">
                  <div class="rounded bg-neon d-flex justify-content-center align-items-center resp-icon" style="width: 55px; height: 55px;">
                    <i class="fa-solid fa-book-bookmark fs-2 resp-fs text-white"></i>
                  </div>
                </div>
                <div class="col-10">
                  <div>
                    <h5>Kurikulum Terupdate</h5>
                    <small class="d-inline-block pe-4">Kurikulum yang kami pakai menyesuaikan keadaan dan kebutuhan di dunia industri yang sedang berjalan di masa kini.</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-3">
            <div >
              <div class="row">
                <div class="col-2">
                  <div class="rounded bg-neon d-flex justify-content-center align-items-center resp-icon" style="width: 55px; height: 55px;">
                    <i class="fa-solid fa-brain fs-2 resp-fs text-white"></i>
                  </div>
                </div>
                <div class="col-10">
                  <div>
                    <h5>Computational Thingking</h5>
                    <small class="d-inline-block pe-4">Selain Materi dan Praktek talent kami dituntut untuk memiliki logika dan mindset komputasi yang kuat agar tetap bisa terus berkembang.</small>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="bg-genbox mt-5 p-5">
    <h4 class="text-center mb-4">Pilihan Program Genbox</h4>
    <div class="container d-flex justify-content-evenly resp-column">
      <div className="pro-card">
        <div className="cover cvr-1">
          <img src="@/assets/img/cover/btcmp.png" class="cover-img" />
        </div>
        <div className="caption">
          <small>Belajar menjadi fullstack dev Website selama 2 bulan full.</small>
        </div>
      </div>
      <div className="pro-card">
        <div className="cover cvr-2">
          <img src="@/assets/img/cover/reg.png" class="cover-img" />
        </div>
        <div className="caption">
          <small>Pembelajaran singkat, padat, dan menjuru ke satu materi.</small>
        </div>
      </div>
      <div className="pro-card">
        <div className="cover cvr-3">
          <img src="@/assets/img/cover/prvt.png" class="cover-img" />
        </div>
        <div className="caption">
          <small>Pembelajaran face to face  langsung dengan mentor</small>
        </div>
      </div>
    </div>
  </div>
  <div class="py-4 container-icon">
    <Vue3Marquee :clone="true">
      <span class="mx-5 tech-icon"><i class="fa-brands fa-js fs-1 text-secondary"></i></span>
      <span class="mx-5 tech-icon"><i class="fa-brands fa-react fs-1 text-secondary"></i></span>
      <span class="mx-5 tech-icon"><i class="fa-brands fa-vuejs fs-1 text-secondary"></i></span>
      <span class="mx-5 tech-icon"><i class="fa-brands fa-laravel fs-1 text-secondary"></i></span>
      <span class="mx-5 tech-icon"><i class="fa-brands fa-php fs-1 text-secondary"></i></span>
      <span class="mx-5 tech-icon"><i class="fa-brands fa-golang fs-1 text-secondary"></i></span>
      <span class="mx-5 tech-icon"><i class="fa-brands fa-python fs-1 text-secondary"></i></span>
      <span class="mx-5 tech-icon"><i class="fa-brands fa-node fs-1 text-secondary"></i></span>
    </Vue3Marquee>
  </div>
  <br>
  <br>
  <div class="container my-5">
    <div class="row mb-4">
      <div class="col-sm d-flex align-items-center">
        <div class="container-fluid">
          <h2>Pilihan Metode Belajar Genbox</h2>
          <p>Pilihan pembelajaran yang bervariasi agar bisa menyesuaikan
          kondisi dan kebutuhanmu untuk belajar</p>
        </div>
      </div>
      <div class="col-sm">
        <div class="card shadow text-center py-5 border-0 rounded">
          <h5>Onsite di Depok Kota</h5>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm mb-4">
        <div class="card shadow text-center py-5 border-0 rounded">
          <h5>Online Via Zoom Meet</h5>
        </div>
      </div>
      <div class="col-sm">
        <div class="card shadow text-center py-5 border-0 rounded">
          <h5>Video Materi Pelatihan</h5>
        </div>
      </div>
    </div>
  </div>
  <div class="container py-5 bg-calm">
    <h4 class="text-center mb-4 position-relative" style="z-index: 99;">Apa kata alumni genbox?</h4>
    <div class="d-flex justify-content-center gap-3 py-5 position-relative resp-column" style="z-index: 99;">
      <div class="card p-3 d-flex flex-column justify-content-between" style="width: 300px;height: 300px;">
        <div>
          <h1>"</h1>
          <small class="px-4 d-flex resp-test">
            Di genbox ini seru dan tidak kaku, pembimbingan step by step jelas dan mudah dipahami dan jawaban dari setiap konsultasi sangat membantu.
          </small>
          <h1 class="text-end">"</h1>
        </div>
        <div class="d-flex align-items-center gap-2">
          <div class="rounded-circle overflow-hidden d-flex justify-content-center align-items-center" style="width: 40px;height: 40px;">
            <img src="@/assets/img/test/1.jpeg" alt="testimoni" style="width: 110%;">
          </div>
          <small class="text-muted">@fajarfernandi | Peserta Privat</small>
        </div>
      </div>
      <div class="card p-3 d-flex flex-column justify-content-between" style="width: 300px;height: 300px;">
        <div>
          <h1>"</h1>
          <small class="px-4 d-flex resp-test">
            Genbox paling jempol dah Materinya baik, penjelasannya terbaik, mentornya menarik, yuk ikutan juga ✊🏼
          </small>
          <h1 class="text-end">"</h1>
        </div>
        <div class="d-flex align-items-center gap-2">
          <div class="rounded-circle overflow-hidden d-flex justify-content-center align-items-center" style="width: 40px;height: 40px;">
            <img src="@/assets/img/test/2.jpg" alt="testimoni" style="width: 110%;">
          </div>
          <small class="text-muted">@Elisabeth | Peserta Bootcamp</small>
        </div>
      </div>
      <div class="card p-3 d-flex flex-column justify-content-between" style="width: 300px;height: 300px;">
        <div>
          <h1>"</h1>
          <small class="px-4 d-flex resp-test">
            Pembelajarnnya tidak kaku, mentornya baik dan tidak sombong, walupun 3 bulan tidak membosankan.
          </small>
          <h1 class="text-end">"</h1>
        </div>
        <div class="d-flex align-items-center gap-2">
          <div class="rounded-circle overflow-hidden d-flex justify-content-center align-items-center" style="width: 40px;height: 40px;">
            <img src="@/assets/img/test/3.jpg" alt="testimoni" style="width: 110%;">
          </div>
          <small class="text-muted">@Syafiq | Peserta Bootcamp</small>
        </div>
      </div>
    </div>
  </div>
  <div class="container my-5 py-5 d-flex align-items-center flex-column">
    <br>
    <br>
    <br>
    <h1 class="fw-light text-center">
      Yuk Tunggu apa lagi mulai <b>karirmu</b> di<br>
      Dunia Digital, Bersama <b>Genbox Academy</b>
    </h1>
    <a href="https://chat.whatsapp.com/Lhi15sSEWqoArnopX5oHPO" class="btn btn-lg btn-neon text-white w-25 resp-w25 mt-4">
      Join Komunitas
    </a>
    <br>
    <br>
    <br>
  </div>
  <div class="bg-sans p-5">
    <div class="container">
      <div class="row">
        <div class="col-md">
          <iframe width="100%" height="350" src="https://www.youtube.com/embed/zrhJZHNDxaU?si=VMDBGafySc8YOEAi" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div class="col-md">
          <h1 class="fw-light">Untuk kamu yang terkendala biaya bisa support channel genbox</h1>
          <p>Materi didalamnya sama seperti materi bootcamp hanya saya tidak ada sesi live meet, support agar ada konten lainnya juga</p>
          <a href="https://youtu.be/adIBZW9TJ_I?si=0BWbEZlEOp760AYY" class="btn btn-lg btn-neon text-white w-75 mt-4">
            Langganan Sekarang <i class="fa-brands fa-youtube ms-2"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
  <FooterRoot />
</template>

<script>
import { Vue3Marquee } from 'vue3-marquee'
import FooterRoot from '@/components/FooterRoot.vue'
import Navbar from "@/components/NavbarRoot"
import Cookies from 'js-cookie'

export default {
  name: 'HomeView',
  components: {
    Vue3Marquee,
    FooterRoot,
    Navbar,
  },
  mounted(){
    const dataAuth = Cookies.get('auth');

    if(dataAuth){
      let auth = JSON.parse(dataAuth)
      let status = auth.status
      if(status === 0){
        this.$router.push('/pay')
      }else if(status === 1){
        this.$router.push('/dashboard')
      }
    }

    document.querySelectorAll('.nav-link')[1].classList.remove('active')
    document.querySelectorAll('.nav-link')[0].classList.add('active') 
  }
}
</script>

<style> 
  span.tech-icon:hover {
    cursor: pointer;
  }

  .container-icon {
    position: relative;
  }

  .container-icon::after {
    position: absolute;
    content: '';
    display: block;
    left: 0 ;top: 0;bottom: 0;
    width: 20%;
    z-index: 999;
    background: linear-gradient(90deg, rgba(248,249,250, 1) 0%, rgba(248,249,250, 1) 50%, rgba(248,249,250, 0.00) 100%);
  }
  
  .container-icon::before {
    position: absolute;
    content: '';
    display: block;
    right: 0 ;top: 0;bottom: 0;
    width: 20%;
    z-index: 999;
    background: linear-gradient(90deg, rgba(248,249,250, 0.00) 0%, rgba(248,249,250, 1) 50%, rgba(248,249,250, 1) 100%);
  }

  .pro-card {
    width: 275px;
    height: 270px;
    background-color: white;
    border-radius: 8px;
  }
  
  .cover {
    position: relative;
    width: 100%;
    height: 68%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    overflow: hidden;
  }

  .cover-img {
      height: 110%;
  }

  .cover:after {
      position: absolute;
      font-size: 32px;
      text-shadow: 0px 2px rgba(34, 40, 49, 0.75);
      top: 0;left: 0;right: 0;bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-weight: bold;
      background-color: rgba(34, 40, 49, 0.75);;
  }

  .cvr-1:after {
      content: 'Bootcamp';
  }

  .cvr-2:after {
      content: 'Reguler';
  }

  .cvr-3:after {
      content: 'Private';
  }

  .caption {
      width: 100%;
      height: 32%;
      padding: 10px 20px;
  }
  
  .bg-neon {
    background-color: #32E0C4;
    box-shadow: 0px 0px 28px rgba(50, 224, 196, 0.75);
  }
  
  .btn-neon {
    background-color: #32E0C4;
    transition: .5s;
  }
  
  .btn-neon:hover {
    background-color: #32E0C4;
    cursor: pointer;
    box-shadow: 0px 0px 28px rgba(50, 224, 196, 0.75);
  }
  
  .text-neon {
    color: #32E0C4;
    text-shadow: 0px 0px 8px rgba(50, 224, 196, 0.15);
  }

  .bg-genbox {
    background-color: #32E0C4;
  }

  .bg-calm {
    /* background-color: #e9ffff; */
    background-image: url(@/assets/img/hero.jpg);
    background-position: center;
    background-size: cover;
    position: relative;
  }

  .bg-sans {
    background-color: #d3fff8;
  }

  .bg-calm::after {
    content: '';
    position: absolute;
    display: block;
    background-color: #32E0C4de;
    top: 0;left: 0;right: 0;bottom: 0;z-index: 0;
  }

  .home {
    width: 100%;
    height: 100vh;
    background-color: #222831;
    position: relative;
  }
  
  header {
    position: relative;
    padding-top: 230px;
    background-image: url('@/assets/img/kotak.svg');
    background-size: 40px;
    height: 100%;
  }

  header::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;left: 0;right: 0;bottom: 0;
    background: radial-gradient(50% 50% at 50% 50%, rgba(34, 40, 49, 0.00) 37.5%, #222831 100%);
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .title {
    font-size: 4.2rem;
  }

  .sub-title {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 1.4rem;
    color: #32E0C4;
    text-shadow: 0px 0px 28px rgba(50, 224, 196, 0.75);
  }

  .char {
    text-shadow: 0px 0px 28px rgba(225, 225, 225, 0.45);
  }

  .btn {
    crusor: pointer !important;
  }
  
  .btn-main {
    border:  1px solid transparent;
    background-clip: padding-box !important;
    background: linear-gradient(165deg, #fff 65%, rgba(255, 255, 255, 0.43));
    text-fill-color: transparent;
    transition: .5s;
  }
  
  .btn-main:hover {
    border: 1px solid #fff;
    background: transparent !important;
  }

  .cover-heading {
    width: 45%;
    position: absolute;
    bottom: 0;right: 13vw;
  }

  @media (max-width: 576px) {
    .resp-column {
      flex-direction: column !important;
      gap: 30px;
      align-items: center;
    }

    .title {
      font-size: 2.2rem;
    }

    .sub-title {
      font-size: .8rem;
    }

    header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      gap: 30px;
      text-align: center;
      padding-top: 140px;
      background-size: 40px;
      height: 100%;
    }

    h1 {
      font-size: 20px;
    }

    h2 {
      font-size: 19px;
    }

    h5 {
      font-size: 16px;
    }

    p {
      font-size: 13px;
    }

    small {
      font-size: 8px;
    }

    .resp-fs {
      font-size: 16px !important;
    }

    .resp-test {
      font-size: 16px !important;
    }

    .resp-py {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }

    .resp-p {
      padding: 10px !important;
    }

    .resp-w25 {
      width: 50% !important;
    }

    .resp-num {
      margin-top: 30px;
      width: 60px !important;
      height: 60px !important;
    }

    .resp-icon {
      width: 35px !important;
      height: 35px !important;
    }
    
    .cover-heading {
      width: 70%;
      position: absolute;
      bottom: 0;right: unset;left: 15vw;
    }
  }
</style>