<template>
  <Navbar />
  <div class="about text-white">
    <section>
        <div class="container">
          <div class="row">
            <div class="col-md-6"> 
              <p class="fw-light sub-title">Semua Tentang Genbox Academy</p>
              <h1 class="title"><span class="char">Gen</span>eration Out<br/>of The <span class="char">Box</span>.</h1>
              <p class="fw-light fs-3">PT Generasi Digital Nasional</p>
              <small>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eveniet quibusdam dignissimos odit sint, et eos distinctio praesentium recusandae placeat veritatis, exercitationem, saepe quasi odio deserunt in quam maiores consequatur iste!</small>
              <br>
              <br>
              <div class="card-box mt-2 p-3">
                <h5>Visi</h5>
                <small>Visi perusahaan Genbox Academy adalah menjadi perusahaan pembelajaran terkemuka di Indonesia yang memberikan pelatihan berkualitas tinggi kepada setiap individu yang ingin mengembangkan kemampuan dan karirnya dalam bidang teknologi.</small>
              </div>
              <div class="card-box mt-2 p-3">
                <h5>Misi</h5>
                <small>Sementara itu, misi perusahaan Genbox Academy adalah menyediakan fasilitas dan program pelatihan yang inovatif dan efektif, serta menyediakan mentor yang terbaik dan berpengalaman untuk membantu setiap individu mencapai potensinya maksimal. Selain itu, perusahaan juga berkomitmen untuk terus memperbarui dan mengembangkan program pelatihan yang tersedia sesuai dengan tren dan kebutuhan pasar, serta menjadi sumber daya yang tak tergantikan bagi para peserta pelatihan dalam mencapai karier yang sukses di masa depan.</small>
              </div>
            </div>
            <div class="col-md-6 overflow-hidden resp-my">
              <div class="h-100 d-flex flex-column justify-content-end" style="transform: translateY(10px);"> 
                <h3>Dokumentasi Kegiatan<br>Di Genbox Academy</h3>                
                <Vue3Marquee :duration="50" :clone="true">
                  <div class="me-3">
                    <img src="@/assets/img/docs/a.jpg" alt="dokumentasi genbox" style="width: 300px;">
                  </div>
                  <div class="me-3">
                    <img src="@/assets/img/docs/aa.jpg" alt="dokumentasi genbox" style="width: 300px;">
                  </div>
                  <div class="me-3">
                    <img src="@/assets/img/docs/b.jpg" alt="dokumentasi genbox" style="width: 300px;">
                  </div>
                  <div class="me-3">
                    <img src="@/assets/img/docs/c.jpg" alt="dokumentasi genbox" style="width: 300px;">
                  </div>
                  <div class="me-3">
                    <img src="@/assets/img/docs/d.jpg" alt="dokumentasi genbox" style="width: 300px;">
                  </div>
                  <div class="me-3">
                    <img src="@/assets/img/docs/e.jpg" alt="dokumentasi genbox" style="width: 300px;">
                  </div>
                  <div class="me-3">
                    <img src="@/assets/img/docs/f.jpg" alt="dokumentasi genbox" style="width: 300px;">
                  </div>
                  <div class="me-3">
                    <img src="@/assets/img/docs/g.jpg" alt="dokumentasi genbox" style="width: 300px;">
                  </div>
                  <div class="me-3">
                    <img src="@/assets/img/docs/h.jpg" alt="dokumentasi genbox" style="width: 300px;">
                  </div>
                </Vue3Marquee>
              </div>
            </div>
          </div>
        </div>
      </section>
  </div>  
  <FooterRoot />
</template>

<script>
import { Vue3Marquee } from 'vue3-marquee'
import FooterRoot from '@/components/FooterRoot.vue'
import Navbar from "@/components/NavbarRoot"
import Cookies from 'js-cookie'

export default {
  name: "AboutView",
  components: {
    Vue3Marquee,
    FooterRoot,
    Navbar,
  },
  mounted(){
    const dataAuth = Cookies.get('auth');

    if(dataAuth){
      let auth = JSON.parse(dataAuth)
      let status = auth.status
      if(status === 0){
        this.$router.push('/pay')
      }else if(status === 1){
        this.$router.push('/dashboard')
      }
    }
    document.querySelectorAll('.nav-link')[0].classList.remove('active')
    document.querySelectorAll('.nav-link')[1].classList.add('active')
  }
}
</script>

<style>
  .card-box {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(12px);
    border-radius: 6px;
  }

  .about {
    width: 100%;
    height: 120vh;
    background-color: #222831;
    position: relative;
  }
  
  section {
    padding-top: 120px;
    position: relative;
    background-image: url('@/assets/img/kotak.svg');
    background-size: 40px;
    height: 100%;
  }

  section::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;left: 0;right: 0;bottom: 0;
    background: radial-gradient(50% 50% at 50% 50%, rgba(34, 40, 49, 0.00) 37.5%, #222831 100%);
  }
  @media (max-width: 576px) {
    .resp-my {
      margin-top: 80px;
      margin-bottom: 80px;
    }

    .about {
      height: unset;
    }
  }
</style>